import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { ReactComponent as ExcelLogo } from "assets/Excel-logo.svg";
import CsvDownloader from "components/CsvDownloader";
import { CompaniesContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useEffect, useState } from "react";
import { CSVDownload } from "react-csv";
import { Company, CompanyLine, TypeTitles, csvHeaders } from "types";
import { getEventFlagText } from "../index";
import * as SC from "./style";

const FilterDrawerFooter = (props: any) => {
  const [showCsvDownloader, setShowCsvDownloader] = useState(false);
  const [mount, setMount] = useState(false);
  const {
    selectedLanguage,
    selectedDictionary,
    selectedTypeTitles,
    selectedCsvHeaders,
    selectedDirection,
  } = useSelectedLanguage();
  const [headers, setHeaders] = useState([...selectedCsvHeaders]);
  const { companiesCount, getCsvCompanies, csvCompanies, loadingCsvCompanies } =
    useContext(CompaniesContext);

  useEffect(() => {
    const newHeaders = [...headers];
    setHeaders(newHeaders);

    return () => {
      setHeaders([...csvHeaders]);
    };
  }, []);
  const getCsvData = async () => {
    await getCsvCompanies();
    setMount(true);
    setMount(false);
  };

  const handleToggleCsvDownloader = () => {
    setShowCsvDownloader((prevState) => !prevState);
  };

  const createCSVData = (companies: Company[]) => {
    let csvData: CompanyLine[] = [];
    if (!companies.length) return csvData;
    for (const company of companies) {
      if (company.securities) {
        for (const security of company.securities) {
          const d: CompanyLine = {
            security: security,
            issuerId: company?.issuerId,
            name: company?.name,
            ticker: company?.ticker,
            isin: company?.isin,
            sector: company?.primarySector?.name,
            esgScore: company?.esgGrade?.grade,
            letterGrade: company?.esgGrade?.letterGrade as any,
            climateGrade: company?.climateGrade.letterGrade,
            relativeEsgScore: company?.esgGrade?.gradeRelativeToSectorAverage
              ? TypeTitles[company?.esgGrade?.gradeRelativeToSectorAverage]
              : "",
            percentileInSector: company?.esgGrade?.percentileInSector,
            percentileInGeneral: company?.esgGrade?.percentileInGeneral,
            envScore: company?.environmental?.grade,
            relativeEnvScore: company?.environmental
              ?.gradeRelativeToSectorAverage
              ? TypeTitles[company?.environmental?.gradeRelativeToSectorAverage]
              : "",
            socialScore: company?.social?.grade,
            relativeSocialScore: company?.social?.gradeRelativeToSectorAverage
              ? TypeTitles[company?.social?.gradeRelativeToSectorAverage]
              : "",
            govScore: company?.governance?.grade,
            relativeGovScore: company?.governance?.gradeRelativeToSectorAverage
              ? TypeTitles[company?.governance?.gradeRelativeToSectorAverage]
              : "",
            events: company?.events
              ?.map((e) => e.description?.replaceAll(/"/g, "'"))
              ?.join(";\n")
              ?.replaceAll(/,/g, ","),
            eventsFlag: getEventFlagText(company),
            accGradesWithoutFactor:
              company?.esgGrade?.grade &&
              company?.esgGrade?.accGradesWithoutFactor &&
              company?.esgGrade?.grade -
                company?.esgGrade?.accGradesWithoutFactor,
            badActivities: company?.badActivities
              ?.map((a) => TypeTitles[a.type])
              .join("; "),
            esgActivities: company?.sdgActivities
              ?.map((a) => TypeTitles[a.type])
              .join("; "),
          };
          csvData.push(d);
        }
      }
    }
    return csvData;
  };
  const csvData: CompanyLine[] = csvCompanies?.length
    ? createCSVData(csvCompanies)
    : [];

  return (
    <>
      <Box
        textAlign="center"
        p=".5rem"
        color="primary"
        sx={{ background: "white" }}
      >
        <Typography variant="h4" color="primary" p=".6rem">
          {companiesCount} {(selectedDictionary as any).companies}
        </Typography>
        <SC.DrawerBox selectedDirection={selectedDirection}>
          {/* MUI will style the ButtonGroup incorrectly if dir="rtl",
           * unless we implement right-to-left fully for the application.
           * See: https://mui.com/material-ui/guides/right-to-left
           */}
          <ButtonGroup
            dir="ltr"
            variant="contained"
            size="large"
            disableElevation
            disableRipple
          >
            <LoadingButton
              variant="contained"
              onClick={getCsvData}
              style={{ width: "10em" }}
            >
              <Typography variant="h5" color="white">
                {loadingCsvCompanies ? (
                  <span>{(selectedDictionary as any).downloading}</span>
                ) : (
                  <span>{(selectedDictionary as any).dataSummary}</span>
                )}
                <span style={{ paddingLeft: "0.5em" }}>
                  <ExcelLogo
                    height="1.2rem"
                    style={{ verticalAlign: "middle", paddingBottom: "0.1em" }}
                  />
                </span>
              </Typography>
            </LoadingButton>
            <Button size="small" onClick={handleToggleCsvDownloader}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          {mount && (
            <CSVDownload target="_self" data={csvData} headers={headers} />
          )}

          {showCsvDownloader && <CsvDownloader />}
        </SC.DrawerBox>
      </Box>
    </>
    // <>
    //   <Box textAlign="center" p=".5rem" color="primary">
    //     <Typography variant="h4" color="primary" p=".6rem">
    //       נמצאו {companiesCount} חברות
    //     </Typography>
    //     <SC.DrawerBox>
    //       <ButtonGroup
    //         dir="ltr"
    //         variant="contained"
    //         size="large"
    //         disableElevation
    //         disableRipple
    //       >
    //         <Button size="small" onClick={handleToggleCsvDownloader}>
    //           <ArrowDropDownIcon />
    //         </Button>
    //         <LoadingButton
    //           variant="contained"
    //           dir="rtl"
    //           onClick={getCsvData}
    //           style={{ width: "10em" }}
    //         >
    //           <Typography variant="h5" color="white">
    //             <span style={{ float: "right", paddingLeft: "0.5em" }}>
    //               <ExcelLogo
    //                 height="1.2rem"
    //                 style={{ verticalAlign: "middle", paddingBottom: "0.1em" }}
    //               />
    //             </span>
    //             {loadingCsvCompanies ? (
    //               <span>מוריד...</span>
    //             ) : (
    //               <span>נתונים מסכם</span>
    //             )}
    //           </Typography>
    //         </LoadingButton>
    //       </ButtonGroup>

    //       {mount && (
    //         <CSVDownload target="_self" data={csvData} headers={headers} />
    //       )}

    //       {showCsvDownloader && <CsvDownloader {...props} />}
    //     </SC.DrawerBox>
    //   </Box>
    // </>
  );
};

export default FilterDrawerFooter;
